exports.onServiceWorkerUpdateFound = () => {
  // const container = document.createElement("div");
  // container.style.cssText =
  //   "horizontal-alignment: center; line-height: 32px; position: absolute; top: 0px; left: 0px; width: 100%; background-color: #313133; color: #ff2a3d; text-align: center; font-family: Arial; z-index:1000; min-height: 88px; ";
  // const content = document.createElement("h3");
  // content.innerHTML = "Aktualisierung verfügbar! <br/> Bitte klicken Sie hier um die aktuelle Version zu laden.";
  // content.style.cssText = "padding-top: 32px; padding-bottom: 32px;";
  // container.onclick = () => {
  //   document.location.reload();
  // };
  // container.appendChild(content);
  // document.body.appendChild(container);
};
