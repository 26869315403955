// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-about-page-tsx": () => import("/opt/build/repo/src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-design-page-tsx": () => import("/opt/build/repo/src/templates/design-page.tsx" /* webpackChunkName: "component---src-templates-design-page-tsx" */),
  "component---src-templates-marketing-page-tsx": () => import("/opt/build/repo/src/templates/marketing-page.tsx" /* webpackChunkName: "component---src-templates-marketing-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("/opt/build/repo/src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-software-page-tsx": () => import("/opt/build/repo/src/templates/software-page.tsx" /* webpackChunkName: "component---src-templates-software-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("/opt/build/repo/src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("/opt/build/repo/src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("/opt/build/repo/src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("/opt/build/repo/src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

